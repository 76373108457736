import React, { Component } from 'react';
import { metadata, utils } from '@ohif/core';

import ConnectedViewer from './ConnectedViewer.js';
import PropTypes from 'prop-types';
import { extensionManager } from './../App.js';
import Dropzone from 'react-dropzone';
import filesToStudies from '../lib/filesToStudies';
import './ViewerLocalFileData.css';
import { withTranslation } from 'react-i18next';
import './view.css'
const { OHIFStudyMetadata } = metadata;
const { studyMetadataManager } = utils;

const dropZoneLinkDialog = (onDrop, i18n, dir) => {
  return (
    <Dropzone onDrop={onDrop} noDrag>
      {({ getRootProps, getInputProps }) => (
        <span {...getRootProps()} className="link-dialog">
          {dir ? (
            <span>
              {i18n('Load folders')}
              <input
                {...getInputProps()}
                webkitdirectory="true"
                mozdirectory="true"
              />
            </span>
          ) : (
            <span>
              {i18n('Load files')}
              <input {...getInputProps()} />
            </span>
          )}
        </span>
      )}
    </Dropzone>
  );
};
const getFileFromUrl = (url, fileName) => {
  return new Promise((resolve, reject) => {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    // xhr.setRequestHeader('Accept', 'image/png');
    xhr.responseType = "blob";
    // 加载时处理
    xhr.onload = () => {
      // 获取返回结果
      blob = xhr.response;
      let file = new File([blob], fileName);
      // 返回结果
      resolve(file);
    };
    xhr.onerror = (e) => {
      reject(e)
    };
    // 发送
    xhr.send();
  });
}
const getFileFromUrl2 = (url) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    // 加载时处理
    xhr.onload = () => {
      // 获取返回结果
      // 返回结果
      resolve(xhr.response);
    };
    xhr.onerror = (e) => {
      reject(e)
    };
    // 发送
    xhr.send();
  });
}
const linksDialogMessage = (onDrop, i18n) => {
  return (
    <>
      {i18n('Or click to ')}
      {dropZoneLinkDialog(onDrop, i18n)}
      {i18n(' or ')}
      {dropZoneLinkDialog(onDrop, i18n, true)}
      {i18n(' from dialog')}
    </>
  );
};

class ViewerLocalFileData extends Component {
  static propTypes = {
    studies: PropTypes.array,
  };

  state = {
    studies: null,
    loading: false,
    error: null,
    a: [],
    b: null,
    width: 0,
    show: false
  };

  updateStudies = studies => {
    // Render the viewer when the data is ready
    studyMetadataManager.purge();

    // Map studies to new format, update metadata manager?
    const updatedStudies = studies.map(study => {
      const studyMetadata = new OHIFStudyMetadata(
        study,
        study.StudyInstanceUID
      );
      const sopClassHandlerModules =
        extensionManager.modules['sopClassHandlerModule'];

      study.displaySets =
        study.displaySets ||
        studyMetadata.createDisplaySets(sopClassHandlerModules);

      studyMetadata.forEachDisplaySet(displayset => {
        displayset.localFile = true;
      });

      studyMetadataManager.add(studyMetadata);

      return study;
    });

    this.setState({
      studies: updatedStudies,
    });
  };
  async componentDidMount() {
    let str = this.props.location.search.slice(5)
    // let a = await getFileFromUrl2("https://yyt-1304014952.cos.ap-chengdu.myqcloud.com/report/ctdata/DJ202210A/DJ20221024A0014/dcm.json")
    let a = await getFileFromUrl2(`https://io.kesen.ltd/${str}/dcm.json`)
    //let a = await getFileFromUrl2(`https://io.kesen.ltd/report/1/ctdata/DJ202303A/DJ20230310A0091/dcm.json`)
    let b = JSON.parse(a)
    let c = []
    let d = []
    let w = 99 / b.length
    b.map(async (item, index) => {
      let k = ''
      try {
        k = await getFileFromUrl(item.Name, 'name')
        c.push(k)
      } catch (error) {
        console.log(error, 'error');

      }
      d.push('b')

      this.setState({
        width: this.state.width + w
      })
      if (b.length > 1000) {
        if (d.length == b.length - 20) {
          const studies = await filesToStudies(c);
          let k = studies.filter(item => item.StudyInstanceUID !== undefined)
          const updatedStudies = this.updateStudies(k);
          if (!updatedStudies) {
            return;
          }
          this.setState({ studies: updatedStudies, loading: false });
        }
      } else {
        if (d.length == b.length) {
          this.setState({
            width: 100
          })
          const studies = await filesToStudies(c);
          let k = studies.filter(item => item.StudyInstanceUID !== undefined)
          const updatedStudies = this.updateStudies(k);
          if (!updatedStudies) {
            return;
          }
          this.setState({ studies: updatedStudies, loading: false });
        }
      }
      setTimeout(() => {
        if (this.state.width < 50) {
          this.setState({
            show: true
          })
        }
      }, 3000)

    })

    // setTimeout(async () => {

    // }, 2000)
    // const studies = await filesToStudies(this.state.a);

    // const updatedStudies = this.updateStudies(studies);

    // if (!updatedStudies) {
    //   return;
    // }

    // this.setState({ studies: updatedStudies, loading: false });
  }
  render() {
    const onDrop = async acceptedFiles => {
      this.setState({ loading: true });
      const studies = await filesToStudies(acceptedFiles);
      const updatedStudies = this.updateStudies(studies);

      if (!updatedStudies) {
        return;
      }

      this.setState({ studies: updatedStudies, loading: false });
    };
    const handleFileChange = async (e) => {
      this.setState({ loading: true });
      let a = []
      a.push(e.target.files[0])
      const studies = await filesToStudies(a);
      const updatedStudies = this.updateStudies(studies);

      if (!updatedStudies) {
        return;
      }

      this.setState({ studies: updatedStudies, loading: false });
    }

    if (this.state.error) {
      return <div>Error: {JSON.stringify(this.state.error)}</div>;
    }

    return (
      <Dropzone onDrop={onDrop} noClick>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ width: '100%', height: '100%', position: "relative" }}>
            {this.state.studies ? (
              <ConnectedViewer
                studies={this.state.studies}
                studyInstanceUIDs={
                  this.state.studies &&
                  this.state.studies.map(a => a.StudyInstanceUID)
                }
              />
            ) : (
              <div className={'drag-drop-instructions'}>
                <div className={'drag-drop-contents'}>
                  {this.state.loading ? (
                    <h3>{this.props.t('Loading...')}</h3>
                  ) : (
                    <>
                      {/* <h3>
                        {this.props.t(
                          'Drag and Drop DICOM files here to load them in the Viewer'
                        )}
                      </h3>
                      <h4>{linksDialogMessage(onDrop, this.props.t)}</h4>
                      <input onChange={(e) => handleFileChange(e)} type="file" name="file" id='type' /> */}
                      {
                        this.state.show ?
                          <div style={{ color: '#FFF', position: "absolute", top: 10, left: "50%", top: "57.6%", transform: 'translatex(-50%)', fontSize: 14 }}>该影像文件过大，正在努力加载中</div> : ''
                      }

                      <div class="progress-8"></div>
                      <div class="text" style={{ textAlign: 'center' }}>{this.state.width.toFixed(2)}%</div>
                    </>
                  )}
                </div>
                {/* <div style={{ width: 400, backgroundColor: "#FFF" }}>
                  <div style={{ backgroundColor: 'blue', width: this.state.width }}>&nbsp;</div>
                </div> */}
              </div>

            )}
          </div>
        )}
      </Dropzone>
    );
  }
}

export default withTranslation('Common')(ViewerLocalFileData);
