/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function DefaultViewport(props) {
  return <div>{JSON.stringify(props)}</div>;
}
